<!-- Change status Modal -->
<div class="modal" id="modal-status-change" tabindex="-1" role="dialog" aria-labelledby="changeStatusModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="changeStatusModalLabel">{{{o.modal_title}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="{{{o.label_close}}}">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container brand-heading-container">
                    <h6 class="brand-heading">JBC.xmpp</h6>
                    <p class="brand-subtitle">{{{o.version_name}}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
