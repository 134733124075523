<div>
    <div class="container brand-heading-container">
        <h1 class="brand-heading brand-heading--inverse">
			<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100px" height="80px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
					viewBox="0 0 22.73 19.32"
					 xmlns:xlink="http://www.w3.org/1999/xlink"
					 xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
					 <defs>
					  <style type="text/css">
					   <![CDATA[
						.fil0 {fill:white}
					   ]]>
					  </style>
					 </defs>
					 <g id="Слой_x0020_1">
					  <metadata id="CorelCorpID_0Corel-Layer"/>
					  <path class="fil0" d="M11.36 0c6.28,0 11.37,4.07 11.37,9.09 0,5.03 -5.09,9.1 -11.37,9.1 -1.36,0 -2.52,-0.21 -3.88,-0.55 -0.52,-0.13 -3.35,1.71 -3.41,1.68 -0.07,-0.03 0.85,-2.72 0.39,-3 -2.72,-1.65 -4.46,-4.28 -4.46,-7.23 0,-5.02 5.09,-9.09 11.36,-9.09zm-1.93 9.26c-0.45,0.51 -1.47,0.4 -1.9,-0.09 -0.57,-0.65 0.09,-1.32 -1.06,-2.27 -1.71,-0.83 -3.17,1.24 -1.95,2.5 0.76,0.79 1.81,0.1 2.26,0.74 0.44,0.62 -0.1,1.53 0.67,2.18 0.58,0.49 1.58,0.54 2.18,-0.01 0.83,-0.74 0.18,-1.41 0.68,-2.16 0.54,-0.52 1.83,-0.44 2.14,0.02 0.46,0.67 -0.15,1.46 0.7,2.17 0.64,0.54 1.66,0.4 2.15,-0.06 0.93,-0.87 0.12,-1.48 0.75,-2.1 0.63,-0.84 1.42,0.22 2.27,-0.93 1.17,-1.59 -0.91,-3.5 -2.38,-2.22 -0.87,0.76 -0.13,1.49 -0.79,2.23 -0.41,0.45 -1.68,0.37 -1.91,0.02 -0.36,-0.54 0.16,-2.67 -1.92,-2.65 -1.78,0.15 -1.26,1.94 -1.89,2.63z"/>
					 </g>
					</svg>
            <span class="brand-name">
                <span class="brand-name__text">JBC<span class="subdued">.xmpp</span></span>
                <p class="byline">XMPP чат для вашего браузера.</p>
				<p class="byline">Бесконечные общения в независимой и децентрализованной системе.</p>
            </span>
    </div>
</div>
